import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import consts from '../../consts'
import { toast } from 'react-toastify'
import axios from 'axios'
import { connect } from 'react-redux'

import { Tooltip } from '@mui/material'

function DetalhesRow({ n, tipo, codconta, descricao, codcontabil, status, index, auth }) {
    const [deleted, setDeleted] = useState(false)
    const [values, setValues] = useState({ n, tipo, codconta, codcontabil, descricao, user: auth.user.name })

    useEffect(() => {
        if (status === 0) {
            setDeleted(true)
        }
    }, [n])

    const handleUpdate = (data) => {
        const { tipo, codconta, descricao, codcontabil } = data
        if (tipo === '' || codconta === '' || descricao === '' || codcontabil === '') {
            return toast.warn('Preencha todos os campos!')
        }
        axios.post(`${consts.API_URL}/classificationCnpj`, data)
            .then(resp => {
                toast.success('Classificação atualizada com sucesso!')
                setEditMode(false)
            }).catch(e => {
                toast.error('Não foi possivel atualizar a Classificação.')
            })
    }

    const changeValues = e => {
        const { name, value } = e.target
        setValues({ ...values, [name]: value })
    }

    const handleDelete = () => {
        const data = { n, status: 0, user: auth.user.name }
        axios.post(`${consts.API_URL}/classficationDelete`, data)
            .then(resp => {
                toast.success('Classificação deletada com sucesso!')
                setDeleted(true)
            }).catch(e => {
                toast.error('Não foi possivel excluir essa Classificação')
            })
    }

    const [editMode, setEditMode] = useState(false)
    return (
        <div className={`grid grid-cols-7 gap-2 mb-3 ${deleted ? 'hidden' : ''} p-4 bg-blue-50 dark:bg-[#2c2c2c] rounded-xl text-black dark:text-white`} key={index}>
            {editMode ? (
                <>
                    {/* <input type='text' className='p-2 border-2 border-blue-400 rounded-md' name='tipo' onChange={changeValues} value={values.tipo} /> */}
                    <select className='p-2 border-2 border-blue-400 rounded-lg bg-white text-black dark:bg-[#1e1e1e] dark:text-white' name='tipo' onChange={changeValues} value={values.tipo}>
                        <option value="CREDIT">Crédito</option>
                        <option value="DEBIT">Débito</option>
                    </select>
                    <input type='text' className='p-2 border-2 border-blue-400 rounded-lg bg-white  text-black dark:bg-[#1e1e1e] dark:text-white' name='codconta' onChange={changeValues} value={values.codconta} />
                    <input type='text' className='p-2 border-2 border-blue-400 rounded-lg bg-white text-black dark:bg-[#1e1e1e] dark:text-white' name='codcontabil' onChange={changeValues} value={values.codcontabil} />
                    <input type='text' className='p-2 border-2 border-blue-400 rounded-lg bg-white col-span-3 text-black dark:bg-[#1e1e1e] dark:text-white' name='descricao' onChange={changeValues} value={values.descricao} />
                    <div className='flex gap-3 justify-center'>
                        <Tooltip title='Confirmar'>
                            <button onClick={() => {
                                handleUpdate(values)
                            }}
                                className='px-4 py-2 bg-[#41A700] rounded-md hover:bg-[#308941]'>
                                <FontAwesomeIcon icon={faCheck} size='lg' />
                            </button>
                        </Tooltip>
                        <Tooltip title='Excluir'>
                            <button className='px-4 py-2 bg-[#FC3D39] text-white rounded-md hover:bg-[#E33437]' onClick={() => handleDelete()}>
                                <FontAwesomeIcon icon={faTrashCan} size='lg' />
                            </button>
                        </Tooltip>
                    </div>
                </>
            ) : (
                <>
                    <span type='text' className='p-2 overflow-auto border-2 border-gray-200 rounded-lg dark:bg-[#1e1e1e] dark:text-white bg-white text-black'>{values.tipo}</span>
                    <span type='text' className='p-2 border-2 border-gray-200 rounded-lg dark:bg-[#1e1e1e] dark:text-white bg-white text-black'>{values.codconta}</span>
                    <span type='text' className='p-2 border-2 border-gray-200 rounded-lg dark:bg-[#1e1e1e] dark:text-white bg-white text-black'>{values.codcontabil}</span>
                    <span type='text' className='p-2 border-2 border-gray-200 rounded-lg dark:bg-[#1e1e1e] dark:text-white col-span-3 bg-white text-black'>{values.descricao}</span>
                    <div className='flex gap-3 justify-center'>
                        <Tooltip title='Editar'>
                            <button onClick={() => setEditMode(true)}
                                className='px-4 py-2 bg-[#CCCCCC] rounded-md hover:bg-[#999999] text-black'>
                                <FontAwesomeIcon icon={faPenToSquare} size='lg' />
                            </button>
                        </Tooltip>
                        <Tooltip title='Excluir'>
                            <button className='px-4 py-2 bg-[#FC3D39] rounded-md hover:bg-[#E33437] text-white' onClick={() => handleDelete()}>
                                <FontAwesomeIcon icon={faTrashCan} size='lg' />
                            </button>
                        </Tooltip>
                    </div>
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({ auth: state.auth })
export default connect(mapStateToProps)(DetalhesRow)