import React, { useState, useEffect } from 'react'
import axios from 'axios'
import consts from '../consts'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function ContaCreate({ cnpj, setConta, auth }) {
    const [open, setOpen] = useState(false)
    const [values, setValues] = useState({ cnpj: cnpj, tipo: '', codconta: '', descricao: '', codcontabil: '', user: auth.user.name })

    const changeValues = e => {
        const { name, value } = e.target
        setValues({ ...values, [name]: value })
    }

    const handleClear = () => {
        setValues({ cnpj: cnpj, tipo: '', codconta: '', descricao: '', codcontabil: '' })
    }

    const handleConfirm = (data) => {
        const { codconta, tipo, descricao, codcontabil } = data

        if (codconta === '' || tipo === '' || descricao === '' || codcontabil === '') {
            return toast.warning('Preencha todos os campos para poder confirmar')
        }

        axios.post(`${consts.API_URL}/classfication`, data)
            .then(resp => {
                toast.success('Classificação criada com sucesso!')
                setValues({ cnpj: cnpj, tipo: '', codconta: '', descricao: '', user: auth.user.name })

                axios.get(`${consts.API_URL}/classificationCnpj`, { params: { cnpj } })
                    .then(resp => {
                        setConta(resp.data)
                    }).catch(e => {
                        console.log('erro', e)
                    })
                setOpen(false)
            }).catch(e => {
                toast.error('Não foi possivel criar a Classificação')
            })
    }

    return (
        <div className='mb-3 mt-3'>
            <button onClick={() => setOpen(true)}
                className="px-4 py-2 font-semibold text-center text-black bg-[#53D769] rounded-lg hover:bg-[#46C263]">
                <FontAwesomeIcon icon={faPlus} size='lg' className='w-5 h-5' /> Adicionar
            </button>

            <div>
                <div className={`fixed inset-0 flex justify-center items-center transition-colors
                    ${open ? "visible bg-black/20" : "invisible"}`}>
                    <div onClick={e => e.stopPropagation()}
                        className={`
                    bg-white rounded-xl shadow p-6 transition-all
                    ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
                    `}>
                        <button onClick={() => setOpen(false)}
                            className='absolute px-2 py-1 text-black rounded-full top-1 right-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div className='flex flex-col w-full mr-4'>
                            <div className='flex flex-col gap-1'>
                                <span className='text-2xl font-bold'>Adicionar Classificação</span>
                                <span className='text-sm'>Tipo de transação</span>
                                <select name='tipo' className='p-2 border b-gray-100 rounded-xl' onChange={changeValues} value={values.tipo}>
                                    <option value="">Selecione uma opção</option>
                                    <option value="CREDIT">Crédito</option>
                                    <option value="DEBIT">Débito</option>
                                </select>
                                <span className='text-sm'>Código da Conta</span>
                                <input type="text" name='codconta' className='p-2 border b-gray-100 rounded-xl' onChange={changeValues} value={values.codconta} />
                                <span className="text-xs text-gray-400">Ex: 1.1.02</span>
                                <span className='text-sm'>Código Contábil</span>
                                <input type="text" name='codcontabil' className='p-2 border b-gray-100 rounded-xl' onChange={changeValues} value={values.codcontabil} />
                                <span className='text-sm'>Descrição da Conta</span>
                                <input type="text" name='descricao' className='p-2 border b-gray-100 rounded-xl' onChange={changeValues} value={values.descricao} />
                                <span className="text-xs text-gray-400">Ex: 1.1.02 - Contribuição Sindical</span>
                                <div className="flex justify-between w-full gap-2 mt-2">
                                    <button className='px-4 py-2 font-semibold' onClick={() => handleClear()}>Cancelar</button>
                                    <button onClick={() => handleConfirm(values)} className='px-4 py-2 font-semibold text-white bg-green-500 rounded-md hover:bg-green-600'>Confirmar</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ auth: state.auth })
export default connect(mapStateToProps)(ContaCreate)