import React, { useState } from "react";
import Modal from "./Modal";
// import Tooltip from './Tooltip'
import { Tooltip } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";

function SelectDropdown({
  memo,
  handleUpdate,
  classification,
  descricaoOr,
  value,
  name,
  idEnvio,
  user_id,
  valueType,
  row_id,
  envioStatus,
  setStatusEnvio,
}) {
  const [dropdown, setDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      {descricaoOr === "Lançamento Desmembrado" ? (
        <Tooltip title="Limpe a Classificação">
          <div
            className="bg-zinc-100 focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 w-72 justify-between flex self-center items-center border border-zinc-200 hover:cursor-not-allowed"
            type="button"
          >
            <span>{descricaoOr}</span>
          </div>
        </Tooltip>
      ) : (
        <div>
          <button
            id="dropdownSearchButton"
            data-dropdown-toggle="dropdownSearch"
            data-dropdown-placement="bottom"
            onClick={() => {
              setDropdown(!dropdown);
            }}
            className="bg-white hover:bg-zinc-100 focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 w-72 justify-between flex self-center items-center border-zinc-200"
            type="button"
          >
            <span className="text-black">{descricaoOr}</span>
            <svg
              className="w-2.5 h-2.5 ml-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
          {/* Dropdown menu */}
          <div
            onMouseLeave={() => setDropdown(false)}
            id="dropdownSearch"
            className={`z-10 ${
              dropdown ? "" : "hidden"
            } bg-white rounded-lg w-72 absolute mt-1 border border-zinc-200 shadow-md`}
          >
            <div className="p-3">
              <label htmlFor="input-group-search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="input-group-search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Procurar classificação"
                />
              </div>
            </div>
            <ul
              className="px-3 pb-3 overflow-y-auto text-sm text-gray-700 h-60"
              aria-labelledby="dropdownSearchButton"
            >
              {classification
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.descricao
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((item, index) => {
                  const { codconta, descricao } = item;

                  return (
                    <li key={index} className="mt-2">
                      <div
                        className="flex items-center pl-2 rounded hover:bg-gray-200 hover:cursor-pointer"
                        onClick={() => {
                          handleUpdate(
                            descricao,
                            codconta,
                            "Classificado com sucesso!",
                            "Não foi possivel classificar!"
                          );
                          setDropdown(false);
                        }}
                      >
                        <label
                          htmlFor="checkbox-item-11"
                          className="w-full py-2 ml-2 text-sm font-medium rounded hover:cursor-pointer"
                        >
                          {descricao}
                        </label>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      )}
      <Modal
        envioStatus={envioStatus}
        setStatusEnvio={setStatusEnvio}
        handleUpdate={handleUpdate}
        classification={classification}
        memo={memo}
        valor={value}
        name={name}
        idEnvio={idEnvio}
        user_id={user_id}
        valueType={valueType}
        row_id={row_id}
      />
      <Tooltip title="Limpar Classificação">
        <div
          onClick={() =>
            handleUpdate(
              null,
              null,
              "Classificação limpa com sucesso",
              "Não foi possivel limpar sua classificação"
            )
          }
          className="px-2 py-1 text-black dark:text-black bg-red-200 rounded-md hover:bg-red-300 hover:cursor-pointer"
        >
          <FontAwesomeIcon icon={faEraser} size="lg" className="w-5 h-5" />
        </div>
      </Tooltip>
    </>
  );
}

export default SelectDropdown;
