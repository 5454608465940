import React, { useState, useEffect } from 'react'
import Chart from 'react-google-charts'
import { cnpjMask } from '../functions/mask';
import axios from 'axios';
import consts from '../consts';
import { monthMask } from '../functions/dates';

function ChartsDetail({ cnpj, razao, banco, fid, agencia, conta }) {
    const [grafico, setGrafico] = useState([])
    // Futuramente adicionar tabela no banco de dados para mudar os meses atuais
    const months = ['202301', '202302', '202303', '202304', '202305', '202306', '202307', '202308', '202309', '202310', '202311', '202312']
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setList([])
        setIsLoading(true)  
        months.map(async (date) => {
            const resp = await axios.get(`${consts.API_URL}/ofxDate`, { params: { cnpj: cnpj, date: date, acctid: conta } })
            await graph(resp, date)
        })
    }, [cnpj])

    const graph = async (resp, date) => {
        if (resp.data.length === 0) {
            setList(old => [...old, { 'id': date, 'month': monthMask(date.replace('2023', '')), credito: 0, debito: 0, total: 0 }])
        } else {
            let total = 0
            let credito = 0
            let debito = 0
            await resp.data.map(item => {
                if (item.TRNTYPE === 'CREDIT' || item.TRNTYPE === 'DEP') {
                    credito += parseFloat(item.TRNAMT)
                }
                if (item.TRNTYPE === 'DEBIT') {
                    if (item.TRNAMT.includes('-')) {
                        debito += parseFloat(item.TRNAMT.replace('-', ''))
                    } else {
                        debito += parseFloat(`${item.TRNAMT}`)
                    }
                }
                return total = item.BALAMT
                // total = total + parseFloat(item.TRNAMT)
            })
            // setGrafico(old => [...old, [monthMask(date.replace('2023', '')), credito, debito, total]])
            // setSaldo(old => old + total)
            // console.log(total)
            setList(old => [...old, { 'id': date, 'month': monthMask(date.replace('2023', '')), credito, debito, total }])
        }
    }

    useEffect(() => {
        const array = []
        list.sort(function (a, b) {
            return a.id - b.id
        }).map(item => {
            // console.log(item)
            return array.push([item.month, item.credito, item.debito, item.total])
            // setGrafico(old => [...old, [item.month, item.credito, item.debito, item.total]])
        })
        array.unshift(["Mês", "Entrada", "Saída", "Saldo"])
        setGrafico(array)
        setTimeout(() => {
            setIsLoading(false)
        }, 2000)
    }, [list])

    const options = {
        title: "Fluxo bancário",
        legend: { position: "bottom" },
        colors: ['#1ced09', '#ed5509', '#1909ed'],
        lineWidth: 3,
        pointSize: 5,
    }
    return (
        <>
            {isLoading ? null :
                (
                    <div className='flex flex-col gap-3 p-5'>
                        <div className='flex flex-col'>
                            <h1 className='text-2xl font-bold'>{razao}</h1 >
                            <div className='flex gap-2'>
                                <span className='font-semibold'>Banco: <span className="font-normal">{fid} - {banco}</span> </span>
                                <span className='font-semibold'>Agência: <span className="font-normal">{agencia}</span></span>
                                <span className='font-semibold'>Conta: <span className="font-normal">{conta}</span></span>
                                <span className='font-semibold'>CNPJ: <span className="font-normal">{cnpjMask(cnpj)}</span></span>
                            </div>
                        </div >
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="400px"
                            data={grafico}
                            options={options}
                        />
                    </div >
                )
            }
        </>
    )
}

export default ChartsDetail