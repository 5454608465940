import React, { useState } from 'react'
import { connect } from 'react-redux'
import { login, signup } from './authActions'
import { bindActionCreators } from 'redux'



function Auth(props) {
  const [form, setForm] = useState({ name: '', email: '', password: '', confirm_password: '' })

  const onSubmit = (values) => {
    values.preventDefault()
    const { login } = props

    const loginForm = { username: form.email, password: form.password }
    login(loginForm)
  }

  const changeForm = e => {
    const { name, value } = e.target

    setForm({ ...form, [name]: value })
  }

  return (
    <div className='flex h-screen dark:text-black dark:bg-zinc-800'>
      <div className='flex flex-col justify-center p-10 m-auto rounded-lg shadow-md w-96 bg-gray-50 dark:bg-zinc-700 dark:text-white'>
        <div className="p-5 pt-2 text-center">
          <span className="text-4xl font-semibold">
            LOWTAX
          </span>
          <span className='font-semibold'>
            cash
          </span>
        </div>
        <form className="flex flex-col self-center gap-5 w-52" onSubmit={onSubmit}>
          <div>
            <span className='text-sm font-semibold'>E-mail</span>
            <input type="input" className="p-2 rounded-md dark:text-black" name='email' placeholder='Insira seu e-mail...' onChange={changeForm} />
          </div>
          <div>
            <span className='text-sm font-semibold'>Senha</span>
            <input type="password" className="p-2 rounded-md dark:text-black" name='password' placeholder='Senha' onChange={changeForm} />
          </div>
          <button className="self-center w-32 px-4 py-2 font-bold bg-green-400 hover:bg-green-500 dark:hover:bg-green-500 dark:bg-green-600 rounded-xl ">Entrar</button>
        </form>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({ login }, dispatch)
export default connect(null, mapDispatchToProps)(Auth)