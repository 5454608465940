import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

function ThemeButton({ setTheme }) {
    const [text, setText] = useState(localStorage.theme)

    return (
        <>
            {text === 'dark' ? (
                <div className="flex gap-1 px-4 py-3 text-sm capitalize transition-colors duration-200 ease-in transform cursor-pointer dark:hover:bg-zinc-600 hover:bg-zinc-200 hover:font-semibold" onClick={() => {
                    localStorage.theme = 'light'
                    setTheme('light')
                    setText('light')
                }}>
                    <FontAwesomeIcon icon={faSun} className='self-center' />
                    <span>Modo Claro (Admin)</span>
                </div>
            ) : (
                <div className="flex gap-1 px-4 py-3 text-sm capitalize transition-colors duration-200 ease-in transform cursor-pointer dark:hover:bg-zinc-600 hover:bg-zinc-200 hover:font-semibold" onClick={() => {
                    localStorage.theme = 'dark'
                    setTheme('dark')
                    setText('dark')
                }}>
                    <FontAwesomeIcon icon={faMoon} className='self-center' />
                    <span>Modo Escuro (Admin)</span>
                </div>
            )}
        </>
    )
}

export default ThemeButton