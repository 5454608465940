import axios from "axios";
import React, { useState, useEffect } from "react";
import consts from "../consts";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCalendar } from "@fortawesome/free-regular-svg-icons";
import ActionButton from "./components/ActionButton";
import { HOME_URL } from "../consts";

function TableRow({
  social,
  banco,
  auth,
  agencia,
  dataini,
  datafim,
  user,
  status,
  idenvio,
  conta,
}) {
  useEffect(() => {
    checkStatus();
    //checkStatusName()
  });

  const [color, setColor] = useState("");
  // const [statusName, setStatusName] = useState('')
  const [statusRender, setStatusRender] = useState(status);

  const deleteEnvio = (idenvio) => {
    axios
      .post(`${consts.API_URL}/enviosDelete`, { id: idenvio })
      .then((resp) => {
        console.log(resp.data);
        setStatusRender(401);
        toast.success("Envio deletado com sucesso!");
      })
      .catch((e) => {
        toast.error("Não foi possivel excluir esse envio");
        // console.log('Nao foi possivel excluir', e)
      });
  };

  const checkStatus = () => {
    switch (status) {
      case 1: // Novo
        setColor("border-green-600");
        break;
      case 2: // Em Preenchimento
        setColor("border-yellow-500");
        break;
      case 3: // Preenchido
        setColor("border-cyan-400");
        break;
      case 4: // Processado
        setColor("border-cyan-500");
        break;
      case 400:
        setColor("border-red-500");
        break;
      default:
        setColor("border-gray-500");
        break;
    }
  };

  // const checkStatusName = () => {
  //     switch (status) {
  //         case 1:
  //             setStatusName('Novo')
  //             break
  //         case 2:
  //             setStatusName('Em Preenchimento')
  //             break
  //         case 3:
  //             setStatusName('Preenchido')
  //             break
  //         case 4:
  //             setStatusName('Preenchido')
  //             break
  //         case 400:
  //             setStatusName('Duplicado')
  //             break
  //         default:
  //             setStatusName('')
  //             break
  //     }
  // }

  return (
    <>
      {statusRender === 0 ||
      statusRender === 401 ||
      statusRender === 402 ? null : (
        <tr
          className={`border-b-gray-200 bg-zinc-50 border-b border-l-8 dark:bg-[#272727] ${color} ${
            status === 4 && auth.user.status === 200
              ? "!border-l-blue-700"
              : null
          }`}
        >
          <td className={`px-6 py-3 font-semibold `}>
            <div className="flex justify-center dark:text-black">
              {/* TODO Criar um componente para os botões para poder organizar melhor o componente */}
              <ActionButton
                statusRender={statusRender}
                deleteEnvio={deleteEnvio}
                idenvio={idenvio}
              />
              {/* {statusRender === 400 ?
                                <>
                                    <Tooltip title='Excluir'>
                                        <span className='p-2 font-medium bg-red-300 rounded-full hover:bg-red-400 hover:cursor-pointer' onClick={() => deleteEnvio(idenvio)}>
                                            <FontAwesomeIcon icon={faTrashCan} size='lg' className='w-5 h-5' />
                                        </span>
                                    </Tooltip>
                                </>
                                :
                                <div className='flex gap-3'>
                                    <Tooltip title='Editar'>
                                        <span className="p-2 font-medium rounded-full bg-zinc-300 hover:bg-zinc-400 hover:cursor-pointer" onClick={() => {
                                            navigate(`/${idenvio}/details`)
                                        }}>
                                            <FontAwesomeIcon icon={faPenToSquare} size='lg' className='w-5 h-5' />
                                        </span>
                                    </Tooltip>
                                    {statusRender === 0 || statusRender === 1 ?
                                        <Tooltip title='Excluir'>
                                            <span className='p-2 font-medium bg-red-300 rounded-full hover:bg-red-400 hover:cursor-pointer' onClick={() => deleteEnvio(idenvio)}>
                                                <FontAwesomeIcon icon={faTrashCan} size='lg' className='w-5 h-5' />
                                            </span>
                                        </Tooltip>
                                        : null}
                                </div>
                            } */}
            </div>
          </td>
          {/* <td className='px-6 py-4 h-fit'>
                        <div className='flex items-center gap-1 p-2'>
                            <span className={`flex w-3 h-3 ${color} rounded-full`}></span>
                            <span className={`text-xs rounded-3xl w-full font-semibold`} >
                                {statusName}
                            </span>
                        </div>
                    </td> */}
          <td className="px-6 py-4 font-semibold">
            <a
              href={`${HOME_URL}${idenvio}/details`}
              className="text-gray-900 hover:text-blue-700"
            >
              {social}
            </a>
          </td>
          <td className="px-6 py-4">{banco}</td>
          <td className="px-6 py-4">{`${agencia}|${conta} `}</td>
          <td className="px-6 py-4">
            <div className="flex gap-2">
              <FontAwesomeIcon
                icon={faCalendar}
                size="lg"
                className="w-5 h-5"
              />
              <span>{dataini}</span>
              <FontAwesomeIcon
                icon={faCalendar}
                size="lg"
                className="w-5 h-5"
              />
              <span>{datafim}</span>
            </div>
          </td>
          <td className="px-6 py-4">
            <div className="flex gap-2">
              <FontAwesomeIcon
                icon={faUser}
                size="lg"
                className="self-center w-5 h-5"
              />
              <span>{user}</span>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default TableRow;
