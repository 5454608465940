import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListUl, faPlus } from '@fortawesome/free-solid-svg-icons'

function PainelLowtax() {
    return (
        <div className='min-h-screen dark:bg-[#121212] dark:text-white'>

            <div className="container pt-5 mx-auto">
                <div className="flex justify-center w-full gap-4">
                    <div className='flex h-12 gap-3 p-4 font-bold border border-green-500 rounded-xl'>
                        <span className='self-center'>Novos</span>
                        <span className='self-center px-2 py-1 font-semibold text-black bg-white rounded-full'>0</span>
                    </div>
                    <div className='flex h-12 gap-3 p-4 font-bold border border-yellow-500 rounded-xl'>
                        <span className='self-center'>Em preenchimento</span>
                        <span className='self-center px-2 py-1 font-semibold text-black bg-white rounded-full'>0</span>
                    </div>
                    <div className='flex h-12 gap-3 p-4 font-bold border border-blue-500 rounded-xl'>
                        <span className='self-center'>Preenchido</span>
                        <span className='self-center px-2 py-1 font-semibold text-black bg-white rounded-full'>0</span>
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <h2 className='text-xl font-semibold'>Usuário (Em desenvolvimento)</h2>
                    <div className='grid grid-cols-5 gap-2'>
                        <span className='flex gap-2 p-4 bg-gray-200 dark:bg-zinc-700 rounded-xl hover:bg-gray-300 dark:hover:bg-zinc-800'>
                            <FontAwesomeIcon icon={faListUl} className='self-center' />
                            <span>Lista Usuários</span>
                        </span>
                        <Link className='flex gap-2 p-4 bg-gray-200 dark:bg-zinc-700 rounded-xl hover:bg-gray-300 dark:hover:bg-zinc-800' to='/createUser'>
                            <FontAwesomeIcon icon={faPlus} className='self-center' />
                            <span>Criar usuário</span>
                        </Link>
                    </div>
                    <h2 className='text-xl font-semibold'>Contas</h2>
                    <div className='grid grid-cols-5 gap-2'>
                        <Link className='p-4 bg-gray-200 dark:bg-zinc-700 rounded-xl hover:bg-gray-300 dark:hover:bg-zinc-800' to='/planoContas'>Empresas</Link>
                    </div>
                    <h2 className='text-xl font-semibold'>Bancários (Em desenvolvimento) </h2>
                    <div className='grid grid-cols-5 gap-2'>
                        <Link to='/bancarios' className='flex gap-2 p-4 bg-gray-200 dark:bg-zinc-700 rounded-xl hover:bg-gray-300 dark:hover:bg-zinc-800'>
                            <FontAwesomeIcon icon={faListUl} className='self-center' />
                            <span>Lista Bancários</span>
                        </Link>
                        <Link className='flex gap-2 p-4 bg-gray-200 dark:bg-zinc-700 rounded-xl hover:bg-gray-300 dark:hover:bg-zinc-800' to='/planoContas'>
                            <FontAwesomeIcon icon={faPlus} className='self-center' />
                            <span>Criar Bancário</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PainelLowtax