import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import consts from '../../consts';
import { CSVLink } from "react-csv";
import axios from 'axios';
import { Tooltip } from '@mui/material'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

function ExcelExport({ id }) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    const [dataSheet, setDataSheet] = useState(null)
    const [idEnvio] = useState(id)

    useEffect(() => {
        handleDownload()
    }, [id])

    const exportToExcel = async () => {
        // console.log(JSON.stringify(dataSheet))
        const ws = XLSX.utils.json_to_sheet(dataSheet)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, `LowtaxCash_Extrato_${id}${fileExtension}`)
    }

    const handleDownload = async () => {
        const request = await axios.get(`${consts.API_URL}/ofx`, { params: { "idenvio": id } })
        const array = []
        request.data.forEach(async item => {
            const { DTPOSTED, TRNTYPE, MEMO, TRNAMT, descconta } = item
            const ano = DTPOSTED.substring(0, 4)
            const mes = DTPOSTED.substring(4, 6)
            const dia = DTPOSTED.substring(6, 8)
            const tipo = TRNTYPE.replace(/\s/g, "") === 'DEBIT' ? 'Débito' : 'Crédito'
            if (descconta === 'Lançamento Desmembrado') {
                const mult = await axios.get(`${consts.API_URL}/ofxmult`, { params: { idenvio: idEnvio, idlancamento: item.id } })
                mult.data.forEach(item => {
                    try {
                        array.push({ 'Data': `${dia}/${mes}/${ano}`, 'Descrição': MEMO, 'Tipo de transação': tipo, 'Valor': item.TRNAMT, 'Classificação': item.descconta || '' })
                    } catch {
                        console.log('Erro')
                    }
                })
            } else {
                array.push({ 'Data': `${dia}/${mes}/${ano}`, 'Descrição': MEMO, 'Tipo de transação': tipo, 'Valor': TRNAMT, 'Classificação': descconta })
            }
        })
        //console.log(array)
        setDataSheet(array)
        return array
    }

    return (
        <button onClick={() => exportToExcel()} className='flex py-1 px-2 bg-[#1D6F42] rounded-md text-white gap-2 hover:bg-[#284b38]'>
            <FontAwesomeIcon icon={faFileExcel} className='self-center' />
            <span className='self-center'>.csv</span>
        </button>
        // <CSVLink separator={'\t'}
        //     filename={`LowtaxCash_Extrato_${id}`} data={dataSheet} headers={headers} onClick={() => handleDownload()} className='flex py-2 px-4 bg-[#1D6F42] rounded-md text-white gap-2 hover:bg-[#3b6e52]'>
        //     <FontAwesomeIcon icon={faFileExcel} className='self-center' />
        //     <span>.csv</span>
        // </CSVLink>
    )
}

export default ExcelExport