import axios from "axios";
import consts from "../consts";
import { toast } from "react-toastify";

export function login(values) {
  return submit(values, `${consts.API_URL}/auth/signin`);
}

export function signup(values) {
  return submit(values, `${consts.API_URL}/auth/signup`);
}

function submit(values, url) {
  return (dispatch) => {
    axios
      .post(url, values)
      .then((response) => {
        dispatch([{ type: "USER_FETCHED", payload: response.data }]);
        toast.success("Login feito com sucesso!");
      })
      .catch((e) => {
        toast.error("Seu email e senha não correspondem. Tente novamente.");
        console.log(`${e.response.data.erros}`);
      });
  };
}

export function logout() {
  return { type: "TOKEN_VALIDATED", payload: false };
}

export function validateToken(token) {
  return (dispatch) => {
    if (token) {
      axios
        .post(`${consts.API_URL}/auth/validateToken`, { token })
        .then((response) => {
          dispatch({ type: "TOKEN_VALIDATED", payload: response.data.valid });
        })
        .catch((e) => {
          dispatch({ type: "TOKEN_VALIDATED", payload: false });
        });
    } else {
      dispatch({ type: "TOKEN_VALIDATED", payload: false });
    }
  };
}
