import React, { useEffect, useState } from 'react'
import axios from 'axios'

import consts from '../consts'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser, faList } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { Tooltip } from '@mui/material'
import { cnpjMask } from '../functions/mask'

function Bancarios() {
  const [lista, setLista] = useState([])
  const [filter, setFilter] = useState('')
  const [viewMode, setViewMode] = useState(localStorage.planoViewMode)

  useEffect(() => {
    axios.get(`${consts.API_URL}/bancarios`)
      .then(resp => {
        setLista(resp.data)
      })
  }, [])

  return (
    <div className='min-h-screen dark:bg-[#121212] dark:text-white'>
      <div className='container h-full pt-10 pb-10 mx-auto'>
        <div className="flex">
          <span className='text-4xl font-bold'>Bancários</span>
        </div>
        <div className="flex justify-between gap-1 mt-5">
          <div className='flex flex-col gap-1'>
            <span className='text-sm'>Filtrar por nome</span>
            <div className='flex gap-2'>
              <input type="text" className='p-3 border border-gray-300 rounded-md outline-none dark:text-black w-96' value={filter} onChange={e => setFilter(e.target.value)} placeholder='Digite o nome da empresa' />
              {filter.length >= 1 ? (
                <Tooltip title='Limpar'>
                  <button className='px-4 py-2 bg-gray-100 rounded-md hover:bg-gray-300 dark:text-black' onClick={() => setFilter('')}>
                    <FontAwesomeIcon icon={faEraser} size={'lg'} />
                  </button>
                </Tooltip>
              ) : null}
            </div>
          </div>
          <div className='flex self-center gap-4 dark:text-black '>
          </div>
        </div>
        <div className="grid justify-between grid-cols-8 gap-4 p-4 mt-5 text-lg font-bold bg-blue-100 dark:bg-slate-700">
          <span className='self-center col-span-2'>Empresa</span>
          <span>Conta</span>
          <span>Agência</span>
          <span>CNPJ</span>
          <span className='col-span-2'>FID - Banco</span>
          <span>Ação</span>
        </div>
        <div className="flex flex-wrap ">
          {lista.filter(val => {
            if (filter === '') {
              return val
            } else if (val.razaosocial.toLowerCase().includes(filter.toLocaleLowerCase())) {
              return val
            }
          }).map((item, index) => {
            return (
              <div key={index} className="grid justify-between w-full grid-cols-8 gap-4 p-4 bg-gray-50 dark:bg-slate-800">
                <span className='self-center col-span-2 font-bold'>{item.razaosocial}</span>
                <span>{item.conta}</span>
                <span>{item.agencia}</span>
                <span>{cnpjMask(item.cnpj)}</span>
                <span className='col-span-2'>{item.fid} - {item.banco}</span>
                <span className='flex justify-center w-full gap-2 px-2 py-1 text-sm text-center bg-yellow-300 rounded-md dark:bg-yellow-700 dark:text-white dark:hover:bg-yellow-600 hover:bg-yellow-400 hover:cursor-pointer'>
                  Editar
                </span>
              </div>
            )
          })}

        </div>
      </div>
    </div>
  )
}

export default Bancarios