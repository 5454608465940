import React, { useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

import consts from '../consts'
import { useState } from 'react'
import ChartsDetail from '../common/ChartsDetail'
import Header from '../common/components/ui/Header'

function Graficos({ auth }) {
    const [cnpjs, setCnpjs] = useState([])
    const [bancarios, setBancarios] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get(`${consts.API_URL}/permissions`, { params: { "iduser": auth.user.id } })
            .then(resp => {
                setCnpjs(resp.data)
            })
    })

    useEffect(() => {
        cnpjs.map(async (item) => {
            await axios.get(`${consts.API_URL}/bancariosCnpj`, { params: { "cnpj": item.cnpj } })
                .then(resp => {
                    resp.data.map((item) => {
                       return setBancarios(old => [...old, item])
                    })
                })
        })
        setLoading(false)
    }, [cnpjs])

    return (
        <div className=''>
            <Header text='Gráficos' />
            {loading ? null : (
                <div className="container flex flex-col gap-3 mx-auto mt-5">
                    {bancarios.map((item, key) => {
                        return (
                            <ChartsDetail key={key} cnpj={item.cnpj} razao={item.razaosocial} banco={item.banco}
                                fid={item.fid} agencia={item.agencia} conta={item.conta}></ChartsDetail>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({ auth: state.auth })
export default connect(mapStateToProps)(Graficos)