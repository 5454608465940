import React, { useEffect, useState } from 'react'

import axios from 'axios'
import consts from '../consts'
import { useParams, useNavigate, redirect, Link } from "react-router-dom";
import ContaCreate from './ContaCreate';
import DetalhesRow from '../common/components/detalhesRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import BackButton from '../common/backButton';

function ContasDetalhe() {
  const { cnpj } = useParams()
  const [conta, setConta] = useState([])
  // const [editMode, setEditMode] = useState(false)

  const redirectLowtax = () => {
    return redirect('/47029643000121/contasDetalhe')
  }

  useEffect(() => {
    axios.get(`${consts.API_URL}/classificationCnpj`, { params: { cnpj } })
      .then(resp => {
        setConta(resp.data)
      }).catch(e => {
        console.log('erro', e)
      })
  }, [cnpj])

  return (
    <>
      <div className='dark:bg-[#121212]'>
        <div className='container flex flex-col mx-auto'>
          <div className='flex pt-5 pb-5 pr-5'>
            <BackButton path='planoContas' text='Voltar' />
          </div>
          <span className='mb-3 text-4xl font-bold dark:text-white'>Plano de Contas Detalhado</span>
          <ContaCreate cnpj={cnpj} setConta={setConta} />
          {cnpj === '47029643000121' ? (
            <div className='flex gap-2 mt-4 mb-4 bg-gray-100 rounded-lg dark:bg-orange-900'>
              <span className='flex pl-3 pr-3 bg-orange-200 rounded-l-lg'>
                <FontAwesomeIcon icon={faTriangleExclamation} className='self-center text-orange-500' />
              </span>
              <span className='pt-4 pb-4 pl-1 pr-4 dark:text-white'>Esse é o plano de contas da <span className='font-semibold'>Lowtax</span>, ele é utilizado como <span className='font-semibold'>Plano de Contas</span> padrão para empresas que não possuem <span className='font-semibold'>Plano de Contas</span> cadastrado no sistema.</span>
            </div>
          ) : conta.length === 0 ? (
            <div className='flex gap-2 mt-4 mb-4 bg-gray-100 rounded-lg'>
              <span className='flex pl-3 pr-3 bg-orange-200 rounded-l-lg'>
                <FontAwesomeIcon icon={faTriangleExclamation} className='self-center text-orange-500' />
              </span>
              <span className='pt-4 pb-4 pl-1 pr-4'>Essa empresa ainda não possui um plano de contas, ela irá utlizar o <span className='font-semibold'>Plano de Contas</span> da <span onClick={() => redirectLowtax()} className='font-semibold text-blue-600 underline hover:cursor-pointer'>Lowtax</span> até que um seja importado</span>
            </div>
          ) : null}
          <div className='grid grid-cols-7 gap-2 mb-2 ml-2 text-black dark:text-white'>
            <span className='font-semibold'>Tipo</span>
            <span className='font-semibold'>Código de conta</span>
            <span className='font-semibold'>Código Contábil</span>
            <span className='col-span-3 font-semibold'>Descrição</span>
            <span className='font-semibold text-center'>Ações</span>
          </div>
          {conta.sort(function (a, b) {
            return a.tipo - b.tipo
          }).map((item, index) => {
            const { n, tipo, descricao, codconta, codcontabil } = item
            return (
              <DetalhesRow key={index} n={n} tipo={tipo} descricao={descricao} codcontabil={codcontabil} codconta={codconta} index={index}></DetalhesRow>
            )
          })}
        </div>
      </div>
    </>


  )
}

export default ContasDetalhe