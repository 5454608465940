import axios from "axios";
import React from "react";
import consts from "../../consts";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";

function TxtExport({ cnpj, idEnvio }) {
  const downloadTxt = () => {
    axios
      .get(`${consts.API_URL}/getTxt`, {
        params: { cnpj: cnpj, idEnvio: idEnvio },
      })
      .then((resp) => {
        const file = new Blob([converteRespToTxt(resp.data)], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(file, `LowtaxCash-${idEnvio}-${cnpj}.txt`);
      });
    // console.log(cnpj, idEnvio);
  };

  const converteRespToTxt = (resp) => {
    let txt = "";
    resp.forEach((item, index) => {
      if (index === 0) {
        txt = `${item.linha}`;
      } else {
        txt = `${txt}\n${item.linha}`;
      }
    });
    return txt;
  };

  // const handleDownload = () => {
  //     const file = new Blob(['Hello, world!'], { type: 'text/plain;charset=utf-8' });
  //     saveAs(file, 'hello_world.txt');
  // };

  return (
    <>
      <button
        onClick={downloadTxt}
        className="text-gray-700 font-semibold px-2 py-1 bg-gray-100 rounded-md hover:bg-gray-200 flex gap-2"
      >
        <FontAwesomeIcon icon={faFileArrowDown} className="self-center" />
        <span className="self-center">Alterdata.txt</span>
      </button>
    </>
  );
}

export default TxtExport;
