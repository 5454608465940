import React from 'react'

import { Routes, Route } from 'react-router-dom'

import Home from './pages/Home'
import Extrato from './pages/Extrato'
import AuthOrApp from './AuthOrApp'
import PrivateRoutes from './auth/PrivateRoutes'
import Contas from './pages/Contas'
import ContasDetalhe from './pages/ContasDetalhe'
// import ContaCreate from './pages/ContaCreate'
import Graficos from './pages/Graficos'
import CreateUser from './pages/CreateUser'
import PainelLowtax from './pages/PainelLowtax'
import Bancarios from './pages/Bancarios'

function Router() {
  return (
    <Routes>
      <Route path='/' element={<AuthOrApp />} />
      <Route path='/home' element={<PrivateRoutes component={<Home />} />} />
      <Route path='/:id/details' element={<PrivateRoutes component={<Extrato />} />} />
      <Route path='/planoContas' element={<PrivateRoutes component={<Contas />} />} />
      <Route path='/:cnpj/contasDetalhe' element={<PrivateRoutes component={<ContasDetalhe />} />} />
      <Route path='/graficos' element={<PrivateRoutes component={<Graficos />} />} />
      <Route path='/createUser' element={<PrivateRoutes component={<CreateUser />} />} />
      <Route path='/painelLowtax' element={<PrivateRoutes component={<PainelLowtax />} />} />
      <Route path='/bancarios' element={<PrivateRoutes component={<Bancarios />} />} />
    </Routes>
  )
}

export default Router