import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { FaRegTrashAlt } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";

export default function MyModal({ statusRender, idenvio, deleteEnvio }) {
    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return (
        <>
            <button href="#" className='flex justify-between w-full gap-3 px-4 py-2 text-sm text-left text-gray-900 hover:bg-red-100 hover:text-red-600' onClick={openModal}>
                Excluir
                <FaRegTrashAlt className='self-center' />
            </button>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title as="h3" className="flex gap-1 text-lg font-medium leading-6 text-red-500">
                                        <IoIosWarning className='text-3xl' />
                                        <span className='self-end font-bold'>Exclusão permanente!</span>
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {statusRender === 400 ?
                                                <p>
                                                    O seu envio duplicado será excluido de nosso sistema!
                                                </p> : <p>
                                                    O seu envio será excluido permanente de nossa base de dados, não será possivel recuperar a não ser que seja enviado novamente o arquivo <span className='font-bold'>.ofx!</span>
                                                </p>
                                            }
                                        </p>
                                    </div>

                                    <div className="flex gap-2 mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                closeModal()
                                                deleteEnvio(idenvio)
                                            }}
                                        >
                                            Excluir permanentemente
                                        </button>
                                        <button type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
