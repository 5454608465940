import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import DialogModal from "./DialogModal";
import { IoIosArrowDown } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ActionButton({ statusRender, idenvio, deleteEnvio }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Opções
          <IoIosArrowDown className="self-center" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 bottom-full mb-2 origin-bottom-right bg-slate-200 rounded-md shadow-lg w-28 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {statusRender < 400 ? (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/${idenvio}/details`}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-900",
                      "flex gap-3 justify-between px-4 py-2 text-sm hover:bg-blue-100 hover:text-blue-600"
                    )}
                  >
                    <span>Acessar</span>
                    <FaArrowRight className="self-center" />
                  </Link>
                )}
              </Menu.Item>
            ) : null}
            {statusRender === 400 ||
            statusRender === 0 ||
            statusRender === 1 ? (
              <Menu.Item>
                {({ active }) => (
                  <DialogModal
                    statusRender={statusRender}
                    idenvio={idenvio}
                    deleteEnvio={deleteEnvio}
                  ></DialogModal>
                )}
              </Menu.Item>
            ) : null}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
