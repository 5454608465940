import React, { useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import consts from '../consts'
import Header from '../common/components/ui/Header'

function CreateUser() {

    const [form, setForm] = useState({ name: '', email: '', password: '', confirm_password: '' })
    const [passwordShown, setPasswordShown] = useState(false)

    const onSubmit = (values) => {
        // values.preventDefault()
        if (values.password !== values.confirm_password) {
            return toast.warning('As senhas devem ser iguais!')
        }
        const loginForm = { username: values.email, password: values.password, name: values.name, email: values.email }
        console.log(loginForm)
        axios.post(`${consts.API_URL}/auth/signup`, loginForm)
            .then(resp => {
                toast.success('Cadastro realizado!')
            }).catch(e => {
                toast.error(e)
            })

    }

    const changeForm = e => {
        const { name, value } = e.target

        setForm({ ...form, [name]: value })
    }

    return (
        <div className='min-h-screen dark:bg-[#121212] dark:text-white'>
            <Header text={"Criar Usuário"} />
            <div className='container pt-10 mx-auto'>                
                <div className="flex justify-center">
                    <div className='flex flex-col gap-2 '>
                        <span className='self-start font-semibold'>Nome</span>
                        <input className='p-2 text-black border w-96 b-gray-100 rounded-xl' name='name' type="text" onChange={changeForm} />
                        <span className='self-start font-semibold'>E-mail</span>
                        <input className='p-2 text-black border w-96 b-gray-100 rounded-xl' name='email' type="text" onChange={changeForm} />
                        <span className='self-start font-semibold'>Senha</span>
                        <div className='flex gap-2'>
                            <input className='p-2 text-black border w-96 b-gray-100 rounded-xl' name='password' type={passwordShown ? "text" : "password"} onChange={changeForm} />
                            <button onMouseEnter={() => setPasswordShown(true)} onMouseLeave={() => setPasswordShown(false)} className='px-4 py-2 text-black bg-gray-200 rounded-xl hover:bg-gray-300'><FontAwesomeIcon icon={faEye} /></button>
                        </div>
                        <span className='self-start font-semibold'>Confirmar Senha</span>
                        <div className='flex gap-2'>
                            <input className='p-2 text-black border w-96 b-gray-100 rounded-xl' name='confirm_password' type={passwordShown ? "text" : "password"} onChange={changeForm} />
                            <button onMouseEnter={() => setPasswordShown(true)} onMouseLeave={() => setPasswordShown(false)} className='px-4 py-2 text-black bg-gray-200 rounded-xl hover:bg-gray-300'><FontAwesomeIcon icon={faEye} /></button>
                        </div>
                        <button onClick={() => onSubmit(form)} className='p-2 mt-3 bg-[#04AA6D] hover:bg-[#358d6d] rounded-xl w-96 dark:text-black font-semibold'>Confirmar</button>
                        <button className='p-2 mt-3 font-semibold bg-gray-800 hover:bg-gray-700 rounded-xl w-96 dark:text-white'>Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUser