import React, { useEffect, useState } from 'react'
import axios from 'axios'

import consts from '../consts'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser, faList } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { Tooltip } from '@mui/material'
import { cnpjMask } from '../functions/mask'
import Header from '../common/components/ui/Header'

function Contas() {
    const [lista, setLista] = useState([])
    const [filter, setFilter] = useState('')
    const [viewMode, setViewMode] = useState(localStorage.planoViewMode)

    useEffect(() => {
        axios.get(`${consts.API_URL}/bancarios`)
            .then(resp => {
                setLista(resp.data)
            })
    }, [])

    return (
        <div className='min-h-screen dark:bg-[#121212] dark:text-white'>
            <Header text='Plano de Contas' />
            <div className='container h-full pt-2 pb-10 mx-auto'>
                <div className="flex justify-between gap-1 mt-5">
                    <div className='flex flex-col gap-1'>
                        <span className='text-sm'>Filtrar por nome</span>
                        <div className='flex gap-2'>
                            <input type="text" className='p-3 border border-gray-300 rounded-md outline-none dark:text-black w-96' value={filter} onChange={e => setFilter(e.target.value)} placeholder='Digite o nome da empresa' />
                            {filter.length >= 1 ? (
                                <Tooltip title='Limpar'>
                                    <button className='px-2 py-1 bg-gray-100 rounded-md hover:bg-gray-300 dark:text-black' onClick={() => setFilter('')}>
                                        <FontAwesomeIcon icon={faEraser} size={'md'} />
                                    </button>
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        {viewMode ? (
                            <Tooltip title='Modo Card'>
                                <span onClick={() => {
                                    setViewMode(false)
                                    localStorage.planoViewMode = false
                                }} className='self-center px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-xl hover:cursor-pointer self'><FontAwesomeIcon icon={faSquare} size='md' /></span>
                            </Tooltip>

                        ) : (
                            <Tooltip title='Modo Lista'>
                                <span onClick={() => {
                                    setViewMode(true)
                                    localStorage.planoViewMode = true
                                }} className='self-center px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-xl hover:cursor-pointer self'><FontAwesomeIcon icon={faList} size='md' /></span>
                            </Tooltip>
                        )}
                    </div>
                </div>
                {viewMode ? (
                    <>
                        <div className="grid justify-between grid-cols-8 gap-4 p-4 mt-5 text-lg font-bold bg-blue-100 dark:bg-slate-700">
                            <span className='self-center col-span-2'>Empresa</span>
                            <span>Conta</span>
                            <span>Agência</span>
                            <span>CNPJ</span>
                            <span className='col-span-2'>FID - Banco</span>
                            <span>Ação</span>
                        </div>
                        <div className="flex flex-wrap ">
                            {lista.filter(val => {
                                if (filter === '') {
                                    return val
                                } else if (val.razaosocial.toLowerCase().includes(filter.toLocaleLowerCase())) {
                                    return val
                                } else {
                                    return null
                                }
                            }).map((item, index) => {
                                return (
                                    <div key={index} className="grid justify-between w-full grid-cols-8 gap-4 p-4 bg-gray-50 dark:bg-slate-800">
                                        <span className='self-center col-span-2 font-bold'>{item.razaosocial}</span>
                                        <span>{item.conta}</span>
                                        <span>{item.agencia}</span>
                                        <span>{cnpjMask(item.cnpj)}</span>
                                        <span className='col-span-2'>{item.fid} - {item.banco}</span>
                                        <span className='flex justify-center w-full gap-2 px-2 py-1 text-sm text-center bg-blue-100 rounded-md dark:bg-blue-700 dark:text-white dark:hover:bg-blue-600 hover:bg-blue-200 hover:cursor-pointer'>
                                            <Link className='self-center p-1 font-semibold' to={`/${item.cnpj}/contasDetalhe`}>Plano de Contas</Link>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="self-center w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                            </svg>
                                        </span>
                                    </div>
                                )
                            })}

                        </div>
                    </>

                ) : (
                    <div className="grid grid-cols-2 gap-4 mt-10 lg:grid-cols-4 md:grid-cols-3 xl:grid-cols-5">
                        {lista.filter(val => {
                            if (filter === '') {
                                return val
                            } else if (val.razaosocial.toLowerCase().includes(filter.toLocaleLowerCase())) {
                                return val
                            } else {
                                return null
                            }
                        }).map((item, index) => {
                            return (
                                <>
                                    <div key={index} className='flex flex-col justify-between w-64 p-4 shadow-md rounded-xl bg-gray-50 dark:text-black dark:bg-gray-700'>
                                        <div className="flex flex-col justify-between w-full gap-4 mb-2 align-items-center">
                                            <span className='w-full text-2xl font-bold text-center dark:text-white'>{item.razaosocial}</span>
                                        </div>
                                        <div className='flex flex-col gap-2 mt-1 text-sm'>
                                            <span className='p-2 bg-white rounded-md'>Conta: {item.conta}</span>
                                            <span className='p-2 bg-white rounded-md'>Agencia {item.agencia}</span>
                                            <span className='p-2 bg-white rounded-md'>Cnpj: {item.cnpj}</span>
                                            <span className='p-2 bg-white rounded-md'>{item.fid} - {item.banco}</span>
                                            <span className='flex justify-center w-full gap-2 px-2 py-1 text-sm text-center bg-blue-100 rounded-md dark:bg-blue-700 dark:text-white dark:hover:bg-blue-600 hover:bg-blue-200 hover:cursor-pointer'>
                                                <Link className='self-center p-1 font-semibold' to={`/${item.cnpj}/contasDetalhe`}>Plano de Contas</Link>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="self-center w-4 h-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Contas