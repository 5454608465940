import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'

function backButton({ path, text }) {
    return (
        <Link to={`/${path}`} className='flex gap-1 p-2 bg-gray-100 rounded-md hover:bg-gray-200 text-gray-700 font-semibold'>
            <FontAwesomeIcon icon={faAnglesLeft} className='self-center' size='sm' />
            <span>{text}</span>
        </Link>
    )
}

export default backButton