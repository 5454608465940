import React, { useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import Routes from './Routes'
import Menu from './common/Menu';

import './App.css';

function App() {
  const [theme, setTheme] = useState(localStorage.theme)
  
  return (
    <div className={`App min-h-screen ${theme}`}>
      <Router>
        <Menu setTheme={setTheme} />
        <Routes />
        <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={true} />
      </Router>
    </div>
  );
}

export default App;
