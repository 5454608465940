import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useLocation, redirect } from 'react-router-dom'

import { logout } from '../auth/authActions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faTableList, faHouse, faAngleDown, faChartSimple, faTableColumns } from '@fortawesome/free-solid-svg-icons'
import ThemeButton from './components/ThemeButton'
import { toast } from 'react-toastify'

function Menu({ logout, auth, setTheme }) {
  const [dropdown, setDropdown] = useState(false)
  const location = useLocation()

  try {
    if (location.pathname === '/') {
      return null
    } else {
      return (
        // <div className='flex justify-center p-5 bg-gray-100 border-b-2 border-l-gray-900'>
        <div className='flex justify-center p-5 bg-gray-100 dark:bg-[#383838] dark:text-white'>
          <div className='container flex justify-between mx-auto'>
            <span className='self-center text-3xl font-bold uppercase'>
              <Link to='/home'>Lowtax<span className='text-sm'>cash</span></Link>
            </span>
            <div className='flex justify-center gap-4'>
              <Link to='/home' className='flex self-center gap-2 hover:cursor-pointer hover:text-zinc-800 dark:hover:text-zinc-300'>
                <FontAwesomeIcon icon={faHouse} size='lg' />
                <span>Início</span>
              </Link>
              <Link to='/graficos' className='flex self-center gap-2 hover:cursor-pointer hover:text-zinc-800 dark:hover:text-zinc-300'>
                <FontAwesomeIcon icon={faChartSimple} size='lg' />
                <span>Gráficos</span>
              </Link>
            </div>
            <span className='flex self-center gap-4'>
              <div className="relative inline-block">
                <button className='relative z-10 flex gap-2 border-transparent rounded-md focus:outline-none' onClick={() => setDropdown(!dropdown)}>
                  <span className='self-center text-lg font-semibold'>Menu</span>
                  <FontAwesomeIcon icon={faAngleDown} className='self-center font-bold' />
                </button>

                {dropdown ? (
                  <div onMouseLeave={() => setDropdown(false)}
                    className="min-w-[250px] absolute right-0 z-20 py-2 mt-2 bg-white rounded-md shadow-lg dark:bg-zinc-800">
                    <div className='flex flex-col px-4 py-3 text-sm capitalize transition-colors duration-200 ease-in transform'>
                      <span className='text-lg font-semibold'>{auth.user.name}</span>
                      <span className='text-sm text-gray-500 lowercase dark:text-gray-100'>{auth.user.email}</span>
                    </div>
                    <hr className='mt-2 mb-2' />
                    <Link onClick={() => setDropdown(false)} to='/' className="block px-4 py-3 text-sm capitalize transition-colors duration-200 ease-in transform dark:hover:bg-zinc-600 hover:bg-zinc-200 hover:font-semibold"><FontAwesomeIcon icon={faHouse} />  Inicio</Link>
                    <Link onClick={() => setDropdown(false)} to='/graficos' className="block px-4 py-3 text-sm capitalize transition-colors duration-200 ease-in transform dark:hover:bg-zinc-600 hover:bg-zinc-200 hover:font-semibold"><FontAwesomeIcon icon={faChartSimple} />  Gráficos</Link>
                    {auth.user.status === 200 ? (
                      <>
                        <ThemeButton setTheme={setTheme} />
                        <hr className='mt-2 mb-2' />
                        <Link onClick={() => setDropdown(false)} to='/planoContas' className="block px-4 py-3 text-sm capitalize transition-colors duration-200 ease-in transform hover:bg-zinc-200 hover:font-semibold dark:hover:bg-zinc-600"><FontAwesomeIcon icon={faTableList} /> Plano de Contas</Link>
                        {/* <Link onClick={() => setDropdown(false)} to='/PainelLowtax' className="block px-4 py-3 text-sm capitalize transition-colors duration-200 ease-in transform hover:bg-zinc-200 hover:font-semibold dark:hover:bg-zinc-600"><FontAwesomeIcon icon={faTableColumns} /> Painel Lowtax</Link> */}
                      </>
                    ) : null}
                    <hr className='mt-2 mb-2' />
                    <span onClick={() => {
                      setDropdown(false)
                      logout()
                      toast.success('Logout realizado com sucesso!')
                    }} className="block px-4 py-3 text-sm capitalize transition-colors duration-200 ease-in transform hover:bg-red-100 hover:cursor-pointer hover:font-semibold hover:text-red-500 dark:hover:bg-red-500 dark:hover:text-red-200"><FontAwesomeIcon icon={faArrowRightFromBracket} /> Sair</span>
                  </div>
                ) : null}
              </div>
            </span>
          </div>
        </div>
      )
    }
  } catch (e) {
    redirect('/')
    setDropdown(false)
  }
}



const mapStateToProps = (state) => ({ auth: state.auth })
const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Menu)