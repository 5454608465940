import React, { useEffect, useState } from "react";
import axios from "axios";

import consts from "../consts";
import { connect } from "react-redux";

import TableRow from "./TableRow";

function Table({ auth }) {
  const [loaded, setLoaded] = useState(false);
  const [enviosArray, setEnviosArray] = useState([]);
  const [cnpjs, setCnpjs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    axios
      .get(`${consts.API_URL}/permissions`, {
        params: { iduser: auth.user.id },
      })
      .then((resp) => {
        setCnpjs(resp.data);
      });
  }, [auth]);

  // useEffect(() => {
  //   if (auth.user.status === 200) {
  //     axios.get(`${consts.API_URL}/enviosAdmin`).then((resp) => {
  //       setEnviosArray([]);
  //       resp.data.map((item) => {
  //         return setEnviosArray((old) => [...old, item]);
  //       });
  //     });
  //   } else {
  //     setEnviosArray([]);
  //     console.log(`Este é o retorno de cnpjs : ${JSON.stringify(cnpjs)}`);
  //     cnpjs.forEach((item) => {
  //       axios
  //         .get(`${consts.API_URL}/envios`, { params: { cnpj: item.cnpj } })
  //         .then((resp) => {
  //           resp.data.map((item) => {
  //             return setEnviosArray((old) => [...old, item]);
  //           });
  //         });
  //       // console.log(`Este é o retorno de Item : ${JSON.stringify(item)}`);
  //     });
  //     setLoaded(true);
  //   }
  // }, [cnpjs, auth]);

  useEffect(() => {
    const fetchEnvios = async () => {
      try {
        let envios = [];

        if (auth.user.status === 200) {
          const resp = await axios.get(`${consts.API_URL}/enviosAdmin`);
          envios = resp.data;
        } else {
          const enviosPromises = cnpjs.map((item) =>
            axios.get(`${consts.API_URL}/envios`, {
              params: { cnpj: item.cnpj },
            })
          );

          const enviosResponses = await Promise.all(enviosPromises);
          envios = enviosResponses.flatMap((resp) => resp.data);
        }

        //ordenando as linhas da tabela de acordo com o banco de dados.
        envios.sort((a, b) => new Date(a.idenvio) - new Date(b.idenvio));

        setEnviosArray(envios);
        setLoaded(true);
      } catch (error) {
        console.error("Error fetching envios:", error);
      }
    };

    fetchEnvios();
  }, [cnpjs, auth]);

  useEffect(() => {
    console.log(`Retorno do enviosArray : ${JSON.stringify(enviosArray)}`);
  }, [enviosArray]);

  return (
    <>
      {loaded && (
        <div className="relative flex flex-col gap-2 mb-10">
          <div className="flex flex-col lg:flex-row lg:justify-betweeen">
            <div className="flex justify-end gap-2 mb-4 lg:justify-start">
              <span className="self-center font-semibold">Filtro</span>
              <select
                name="select"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="px-2 py-1 font-semibold border rounded-md outline-none w-26 border-zinc-300 dark:text-gray-900"
              >
                <option value="" selected disabled hidden></option>
                <option value="1">Novo</option>
                <option value="2">Em Preenchimento</option>
                <option value="3">Preenchido</option>
                {auth.user.status === 200 ? (
                  <option value="4">Processado</option>
                ) : null}
              </select>
              {searchTerm !== "" ? (
                <button
                  onClick={() => setSearchTerm("")}
                  className="px-2 py-1 font-semibold bg-gray-100 rounded-md hover:bg-gray-200 w-36 dark:text-gray-800"
                >
                  Limpar filtro
                </button>
              ) : null}
            </div>
            <div className="flex justify-end w-full gap-2 p-3 font-semibold">
              <span className="flex self-center w-3 h-3 bg-green-500 rounded-full"></span>
              <span className="self-center">Novo</span>
              <span className="flex self-center w-3 h-3 bg-yellow-500 rounded-full"></span>
              <span className="self-center">Em Preenchimento</span>
              <span className="flex self-center w-3 h-3 rounded-full bg-cyan-400"></span>
              <span className="self-center">Preenchido</span>
              {auth.user.status === 200 ? (
                <>
                  <span className="flex self-center w-3 h-3 bg-blue-700 rounded-full"></span>
                  <span className="self-center">Processado</span>
                </>
              ) : null}
            </div>
          </div>
          <div>
            <table className=" relative w-full bg-blue-100 dark:bg-[#383838] border-spacing-2 overflow-x-auto">
              <thead className="sticky top-0 text-gray-700 bg-blue-100 uppercase border-b dark:text-white">
                <tr className="text-left">
                  <th className="sticky top-0 px-6 py-4"></th>
                  <th className="sticky top-0 px-6 py-4">Empresa</th>
                  <th className="sticky top-0 px-6 py-4">Banco</th>
                  <th className="sticky top-0 px-6 py-4">Agência|Conta</th>
                  <th className="sticky top-0 px-6 py-4">Periodo do Extrato</th>
                  <th className="sticky top-0 px-6 py-4">Enviado por</th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-[#272727] pb-10">
                {enviosArray
                  .filter((val) => {
                    if (searchTerm === "") {
                      return val;
                    } else if (val.status.toString() === searchTerm) {
                      return val;
                    } else {
                      return null;
                    }
                  })
                  .map((item) => {
                    console.log(
                      `Retorno item renderizado: ${JSON.stringify(item)}`
                    );
                    return (
                      <TableRow
                        key={item.idenvio}
                        social={item.razaosocial}
                        banco={item.banco}
                        agencia={item.agencia.padStart(4, "0")}
                        dataini={item.dataini}
                        auth={auth}
                        conta={item.conta}
                        datafim={item.datafim}
                        user={item.username}
                        status={item.status}
                        idenvio={item.idenvio}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps)(Table);
