import React, { useState, useEffect } from "react";
import axios from "axios";

import BackButton from "../common/backButton";
import { useParams } from "react-router-dom";
import ExtratoRow from "../common/ExtratoRow";
import { cnpjMask } from "../functions/mask";
import { monthMask } from "../functions/dates";
import consts from "../consts";
import ExcelExport from "../common/components/ExcelExport";
import { connect } from "react-redux";
import TxtExport from "../common/components/TxtExport";
import Header from "../common/components/ui/Header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function Extrato({ auth }) {
  const { id } = useParams();
  const idEnvio = id;
  const [extrato, setExtrato] = useState([]);
  const [statusEnvio, setStatusEnvio] = useState(1);
  const [envio, setEnvio] = useState({});
  const [contaId, setContaId] = useState(null);
  const [dataIni, setDataIni] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [iniMes, setIniMes] = useState("01");
  const [fimMes, setFimMes] = useState("01");
  const [createdBy, setCreatedBy] = useState("");
  const [credit, setCredit] = useState([]);
  const [debit, setDebit] = useState([]);
  const [cnpj, setCnpj] = useState("");
  const [banco, setBanco] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [completed, setCompleted] = useState(false);

  const fetchEnvio = async () => {
    const request = await axios.get(`${consts.API_URL}/ofx`, {
      params: { idenvio: id },
    });
    const { ACCTID, DTSTART, DTEND, cnpj, ORG } = request.data[0];
    setCnpj(cnpj);
    setDataIni(DTSTART);
    setDataFim(DTEND);
    setIniMes(monthMask(DTSTART.substring(4, 6)));
    setBanco(ORG);

    setContaId(ACCTID);
    // const number = request.data.length
    // const lastObj = request.data[number - 1]
    // setDataFim(lastObj.DTPOSTED)
    setFimMes(monthMask(DTEND.substring(4, 6)));
    setExtrato(request.data);
    let conta = 0;
    request.data.forEach((item) => {
      if (item.descconta !== null) {
        conta++;
      }
    });
    if (conta === request.data.length) {
      setCompleted(true);
    }
  };

  useEffect(() => {
    fetchEnvio();

    axios
      .get(`${consts.API_URL}/enviosId`, { params: { idenvio: id } })
      .then((resp) => {
        setEnvio(resp.data[0]);
        setCreatedBy(resp.data[0].username);
        setIsLoaded(true);
        setStatusEnvio(resp.data[0].status);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`${consts.API_URL}/classification`, {
        params: { type: "DEBIT", cnpj },
      })
      .then((resp) => {
        setDebit(resp.data);
      });
    axios
      .get(`${consts.API_URL}/classification`, {
        params: { type: "CREDIT", cnpj },
      })
      .then((resp) => {
        setCredit(resp.data);
      });
  }, [cnpj]);

  return (
    <div className="min-h-screen dark:bg-[#121212] dark:text-white">
      <Header text="Detalhes do Extrato" />
      {isLoaded && (
        <div className="container mx-auto">
          <div className="flex flex-col gap-3 p-5">
            <div className="flex justify-between gap-2">
              <BackButton path="home" text="Voltar" />
              <div className="flex gap-2">
                {statusEnvio !== 3 && statusEnvio !== 4 ? null : (
                  <>
                    <ExcelExport id={id} />
                  </>
                )}
                {auth.user.status === 200 ? (
                  <TxtExport cnpj={envio.cnpj} idEnvio={id}></TxtExport>
                ) : null}
                {completed && envio.status !== 3 && envio.status !== 4 ? (
                  <span
                    onClick={() => {
                      axios
                        .post(`${consts.API_URL}/updateEnvioStatus`, {
                          id,
                          status: 3,
                        })
                        .then((_) => {
                          toast.success("Envio concluido com sucesso!");
                          setStatusEnvio(3);
                        });
                    }}
                    className="gap-2 px-4 py-2 font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-600 hover:cursor-pointer"
                  >
                    Concluir
                  </span>
                ) : null}
              </div>
            </div>
            <span className="text-3xl font-bold">{envio.razaosocial}</span>
            <div className="flex gap-4">
              <span className="font-semibold">
                CNPJ:{" "}
                <span className="font-normal">{cnpjMask(envio.cnpj)}</span>
              </span>
              <span className="font-semibold">
                Banco: <span className="font-normal">{envio.banco}</span>
              </span>
              <span className="font-semibold">
                Agência:{" "}
                <span className="font-normal">
                  {envio.agencia.padStart(4, "0")}
                </span>
              </span>
              <span className="font-semibold">
                Conta: <span className="font-normal">{contaId}</span>
              </span>
            </div>
            <div className="flex gap-4">
              <span className="font-semibold">
                Enviado por <span className="font-normal">{createdBy}</span>
              </span>
            </div>
            <span></span>

            <span className="font-semibold">
              {dataIni.substring(6, 8)} de {iniMes} de {dataIni.substring(0, 4)}{" "}
              a {dataFim.substring(6, 8)} de {fimMes} de{" "}
              {dataFim.substring(0, 4)}
            </span>
            <div className="flex gap-2">
              <div className="flex gap-1">
                <FontAwesomeIcon
                  icon={faFilter}
                  className="self-center"
                  size="lg"
                />
                <span className="self-center text-sm">
                  Filtrar por descrição
                </span>
              </div>
              <input
                type="text"
                className="p-1 text-black border border-blue-200 rounded-md outline-none"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <button
                className={`px-2 py-1 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 ${
                  searchTerm === "" ? "" : "hidden"
                }`}
                onClick={() => setSearchTerm("")}
              >
                Limpar filtro
              </button>
            </div>
          </div>

          <table className="relative w-full pb-5 table-auto">
            <thead className="sticky top-0 text-gray-700 uppercase bg-blue-100">
              <tr className="text-left">
                <th className="sticky top-0 px-6 py-3">Data</th>
                <th className="sticky top-0 px-6 py-3">Descrição</th>
                <th className="sticky top-0 px-6 py-3">Entradas</th>
                <th className="sticky top-0 px-6 py-3">Saídas</th>
                <th className="sticky top-0 px-6 py-3">Classificação</th>
              </tr>
            </thead>
            <tbody>
              {extrato
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.MEMO.toLowerCase().includes(
                      searchTerm.toLocaleLowerCase()
                    )
                  ) {
                    return val;
                  }
                })
                .map((item, index) => {
                  const { id, DTPOSTED, TRNTYPE, MEMO, TRNAMT, descconta } =
                    item;
                  return (
                    <ExtratoRow
                      setCompleted={setCompleted}
                      key={index}
                      row_id={id}
                      dateDay={DTPOSTED}
                      idEnvio={idEnvio}
                      envioStatus={statusEnvio}
                      setStatusEnvio={setStatusEnvio}
                      desc={MEMO}
                      valueType={TRNTYPE.replace(/\s/g, "")}
                      value={TRNAMT}
                      descconta={descconta}
                      credit={credit}
                      debit={debit}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps)(Extrato);
