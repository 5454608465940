import React, { useState, useEffect } from "react";
import axios from "axios";
import consts from "../consts";
import { toast } from "react-toastify";
import { CurrencyInput } from "react-currency-mask";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

function Modal({
  valor,
  name,
  user_id,
  valueType,
  idEnvio,
  row_id,
  classification,
  envioStatus,
  setStatusEnvio,
  handleUpdate,
}) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    descricao: "",
    valor: "",
    observacao: "",
    codconta: "",
  });
  const [arrayList, setArrayList] = useState([]);
  const [total, setTotal] = useState(parseFloat(valor.replace("-", "")));
  const [dropdown, setDropdown] = useState(false);
  const [descricaoOr, setDescricaoOr] = useState(" - ");
  const [searchTerm, setSearchTerm] = useState("");

  const changeValues = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const changeNumber = (e, masked, original) => {
    // const { value } = e.target
    // var numberPattern = /\d+/g;
    // console.log(value.match(numberPattern))
    // setValues({ ...values, valor: value.match(numberPattern) })
    // console.log('Masked :', masked)
    // console.log('Original :', original)
    // console.log('Final :', masked.replace(' ','' ).replace(/\$/g, '').replace('R', ''))
    const valueReplace = masked
      .replace(" ", "")
      .replace(/\$/g, "")
      .replace("R", "");
    console.log(valueReplace.replace(/\s/g, "").replace(",", "."));
    setValues({
      ...values,
      valor: valueReplace.replace(/\s/g, "").replace(".", "").replace(",", "."),
    });
  };

  // useEffect(() => {
  //     const { valor } = values
  //     if (valor.includes('-') || valor.includes('--')) {
  //         setValues({ ...values, valor: valor.replace('-', '') })
  //     }
  // }, [values])

  const handleChange = (name, value, codconta) => {
    setValues({ ...values, [name]: value, codconta: codconta });
    setDescricaoOr(value);
  };

  useEffect(() => {
    axios
      .get(`${consts.API_URL}/ofxmult`, {
        params: { idenvio: idEnvio, idlancamento: row_id },
      })
      .then((resp) => {
        const list = [];
        resp.data.forEach((item) => {
          const values = {
            id: item.n,
            descricao: item.descconta,
            valor: item.TRNAMT,
            codconta: item.codconta,
            observacao: item.observacao,
          };
          list.push(values);
        });
        setArrayList(list);
      });
  }, [idEnvio, row_id, open]);

  const addDesc = () => {
    if (values.descricao === "") {
      toast.warning("A classificação está em branco");
    } else if (values.valor <= 0 || values.valor === null) {
      toast.warning("O valor está zerado");
    } else {
      setArrayList([...arrayList, values]);
      setValues({ descricao: "", valor: "", observacao: "", codconta: "" });
      setDescricaoOr(" - ");
    }
  };

  const handleRemoveItem = async (idx, valorItem, id) => {
    const temp = [...arrayList];
    temp.splice(idx, 1);
    setArrayList(temp);
    setTotal((prev) => prev + parseFloat(valorItem));
    if (id) {
      const data = { n: id, status: 0 };
      await axios.post(`${consts.API_URL}/ofxmultUpdate`, data).then((_) => {
        toast.success("Status alterado para 0");
      });
    }
  };

  const handleConfirm = async () => {
    await axios
      .get(`${consts.API_URL}/ofxmult`, {
        params: { idenvio: idEnvio, idlancamento: row_id },
      })
      .then((resp) => {
        resp.data.forEach((item) => {
          const itemId = item.n;
          const data = { n: itemId, status: 0 };
          axios.post(`${consts.API_URL}/ofxmultUpdate`, data).then((resp) => {
            //toast.success('Status alterado para 0')
          });
        });
      });

    if (envioStatus === 1) {
      await axios
        .post(`${consts.API_URL}/updateEnvioStatus`, { status: 2, id: idEnvio })
        .then((_) => {
          setStatusEnvio(2);
        });
    }

    arrayList.forEach((item) => {
      const data = {
        classification: item.descricao,
        lancamento: row_id,
        idEnvio: idEnvio,
        name: name,
        codconta: item.codconta,
        valueType: valueType,
        value: item.valor,
        descricao: valor,
        userId: user_id,
        observacao: item.observacao,
      };

      axios
        .post(`${consts.API_URL}/ofxmult`, data)
        .then((resp) => {
          // toast.success('Categoria Personalizada com sucesso!')
          handleUpdate(
            "Lançamento Desmembrado",
            "",
            "Classificação Desmembrada com sucesso!",
            "Não foi possivel desmembrar sua classificação"
          );
          setOpen(false);
        })
        .catch((e) => {
          toast.error("Não foi possivel personalizar a categoria", e);
        });
    });
  };

  useEffect(() => {
    let temp = parseFloat(valor.replace("-", ""));
    arrayList.forEach((item) => {
      const valor = item.valor.replace(",", ".");
      temp = temp - parseFloat(valor);
    });
    setTotal(temp);
  }, [arrayList]);

  return (
    <div className="px-5 py-2">
      <Tooltip title="Desmembrar Lançamento">
        <button
          onClick={() => setOpen(true)}
          className="text-black flex items-center justify-center px-2 py-1 font-semibold rounded-lg bg-zinc-200 hover:bg-zinc-300"
        >
          <FontAwesomeIcon icon={faPenToSquare} size="lg" className="w-5 h-5" />
        </button>
      </Tooltip>

      <div>
        <div
          className={`fixed inset-0 flex justify-center items-center transition-colors
                    ${open ? "visible bg-black/20" : "invisible"}`}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={`
                    bg-white rounded-xl shadow p-6 transition-all
                    ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
                    `}
          >
            <button
              onClick={() => setOpen(false)}
              className="absolute px-2 py-1 text-black rounded-full top-1 right-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="flex flex-col mr-4">
              <div className="flex flex-col">
                <span className="text-2xl font-bold">
                  Desmembrar Lançamentos
                </span>
                <span className="flex max-w-md gap-2 mt-1 text-sm bg-gray-100 rounded-xl">
                  <span className="flex pl-3 pr-3 bg-orange-200 rounded-l-md">
                    <FontAwesomeIcon
                      icon={faInfo}
                      size="xl"
                      className="self-center text-orange-500"
                    />
                  </span>
                  <span className="pt-4 pb-4 pr-5">
                    O recurso "Desmembrar Lançamentos" permite que você divida
                    um lançamento financeiro em partes menores, que podem ser
                    classificadas individualmente.
                  </span>
                </span>
                <div className="flex flex-col gap-2 mt-2 mb-2">
                  <div className="flex flex-col">
                    <span className="text-sm">Classificação </span>
                    <div>
                      <button
                        id="dropdownSearchButton"
                        data-dropdown-toggle="dropdownSearch"
                        data-dropdown-placement="bottom"
                        onClick={() => {
                          setDropdown(!dropdown);
                        }}
                        className="bg-white hover:bg-zinc-100 focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 w-full justify-between flex self-center items-center border border-zinc-200"
                        type="button"
                      >
                        <span>{descricaoOr}</span>
                        <svg
                          className="w-2.5 h-2.5 ml-2.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </button>
                      {/* Dropdown menu */}
                      <div
                        onMouseLeave={() => setDropdown(false)}
                        id="dropdownSearch"
                        className={`z-10 ${
                          dropdown ? "" : "hidden"
                        } bg-white rounded-lg w-96 absolute mt-1 border border-zinc-200 shadow-md`}
                      >
                        <div className="p-3">
                          <label
                            htmlFor="input-group-search"
                            className="sr-only"
                          >
                            Search
                          </label>
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg
                                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                            </div>
                            <input
                              type="text"
                              id="input-group-search"
                              onChange={(e) => setSearchTerm(e.target.value)}
                              value={searchTerm}
                              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="Procurar classificação"
                            />
                          </div>
                        </div>
                        <ul
                          className="px-3 pb-3 overflow-y-auto text-sm text-gray-700 h-60"
                          aria-labelledby="dropdownSearchButton"
                        >
                          {classification
                            .filter((val) => {
                              if (searchTerm === "") {
                                return val;
                              } else if (
                                val.descricao
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              ) {
                                return val;
                              }
                            })
                            .map((item, index) => {
                              const { codconta, descricao } = item;

                              return (
                                <li key={index} className="mt-2">
                                  <div
                                    className="flex items-center pl-2 rounded hover:bg-gray-200 hover:cursor-pointer"
                                    onClick={() => {
                                      handleChange(
                                        "descricao",
                                        descricao,
                                        codconta
                                      );
                                      setDropdown(false);
                                    }}
                                  >
                                    <label
                                      htmlFor="checkbox-item-11"
                                      className="w-full py-2 ml-2 text-sm font-medium rounded hover:cursor-pointer"
                                    >
                                      {descricao}
                                    </label>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm">Valor </span>
                    <CurrencyInput
                      placeholder="Digite o valor do lançamento"
                      className="p-2 border b-gray-100 rounded-xl [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      value={values.valor}
                      onChangeValue={(e, original, masked) =>
                        changeNumber(e, masked, original)
                      }
                    />
                    {/* <input className='p-2 border b-gray-100 rounded-xl [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none' name='valor' value={values.valor}
                                            type='text' pattern="[0-9.,]+" onChange={changeNumber} autocomplete="off" /> */}
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm">Observação </span>
                    <input
                      className="p-2 border b-gray-100 rounded-xl"
                      name="observacao"
                      value={values.observacao}
                      type="text"
                      onChange={changeValues}
                    />
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={() => addDesc()}
                    className="px-4 py-2 mb-5 font-semibold bg-gray-200 rounded-md hover:bg-gray-300"
                  >
                    Adicionar
                  </button>
                </div>
              </div>
              {arrayList.length <= 0 ? null : (
                <table className="table-auto">
                  <thead className="bg-blue-200">
                    <th className="px-4 py-2">Classificação</th>
                    <th className="px-4 py-2">Valor</th>
                    <th className="px-4 py-2">Observação</th>
                    <th className="px-4 py-2">Ações</th>
                  </thead>
                  <tbody>
                    {arrayList.map((item, index) => {
                      return (
                        <tr className="even:bg-gray-100" key={index}>
                          <td className="px-4 py-2">{item.descricao}</td>
                          <td className="px-4 py-2">R$ {item.valor}</td>
                          <td className="px-4 py-2">{item.observacao}</td>
                          <td className="px-4 py-2">
                            <div className="flex justify-center">
                              <svg
                                onClick={() =>
                                  handleRemoveItem(index, item.valor, item.id)
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 font-bold hover:cursor-pointer hover:text-gray-800"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}

              <div className="flex flex-col mt-2">
                <span className="mb-2 font-semibold">
                  Diferença:{" "}
                  <span
                    className={`${
                      total === 0 ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    R$ {total}
                  </span>
                </span>
                {total !== 0 ? (
                  <div>
                    <span className="text-red-500">
                      Valor não corresponde ao total
                    </span>
                  </div>
                ) : (
                  <div className="flex justify-end">
                    <button
                      onClick={() => handleConfirm()}
                      className="px-4 py-2 font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-700"
                    >
                      Confirmar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
