import React, { useState, useEffect } from "react";
import axios from "axios";
import consts from "../consts";
import SelectDropdown from "./SelectDropdown";

import { connect } from "react-redux";
import { toast } from "react-toastify";

function ExtratoRow({
  dateDay,
  desc,
  valueType,
  value,
  row_id,
  descconta,
  auth,
  credit,
  debit,
  idEnvio,
  envioStatus,
  setStatusEnvio,
  setCompleted,
}) {
  const [direction, setDirection] = useState(true);
  const [classification, setClassification] = useState([]);
  const [descricaoOr, setDescricaoOr] = useState(" - ");

  useEffect(() => {
    if (valueType === "DEBIT") {
      setDirection(false);
      setClassification(debit);
    } else if (valueType === "CREDIT" || valueType === "DEP") {
      setClassification(credit);
    }

    if (descconta !== "\\N") {
      setDescricaoOr(descconta);
    }
  }, [valueType, value, descconta, credit, debit]);

  const handleUpdate = async (desc, cod, success, error) => {
    const data = {
      id: row_id,
      classification: desc,
      name: auth.user.name,
      codconta: cod,
    };

    await axios
      .post(`${consts.API_URL}/ofx`, data)
      .then((resp) => {
        // console.log(resp.data)
        setDescricaoOr(desc);
        toast.success(success);
      })
      .catch((e) => {
        // console.log('Não foi possivel atualizar')
        toast.error(error);
      });

    if (envioStatus === 1) {
      await axios
        .post(`${consts.API_URL}/updateEnvioStatus`, { status: 2, id: idEnvio })
        .then((_) => {
          setStatusEnvio(2);
        });
    }
    verifyCompleteded(desc);
  };

  const verifyCompleteded = async (desc) => {
    const request = await axios.get(`${consts.API_URL}/ofx`, {
      params: { idenvio: idEnvio },
    });
    let conta = 0;
    request.data.forEach((item) => {
      if (item.descconta !== null) {
        conta++;
      }
    });
    if (conta === request.data.length) {
      setCompleted(true);
    }
    if (!desc) {
      setCompleted(false);
    }
  };

  const ano = dateDay.substring(0, 4);
  const mes = dateDay.substring(4, 6);
  const dia = dateDay.substring(6, 8);

  return (
    <>
      {envioStatus === 3 ? (
        <tr className="h-16 border-b even:bg-gray-100 dark:even:bg-slate-700 ">
          <td className="px-6 py-3 font-semibold">{`${dia}/${mes}/${ano}`}</td>
          <td className="px-6 py-3">{desc}</td>
          {direction ? (
            <>
              <td className="px-6 py-3">
                <div className="flex gap-2">
                  <span>{value}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="self-center w-5 h-5 text-green-800 dark:text-green-400"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.47 2.47a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06l-6.22-6.22V21a.75.75 0 01-1.5 0V4.81l-6.22 6.22a.75.75 0 11-1.06-1.06l7.5-7.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </td>
              <td className="px-6 py-3"></td>
              <td className="px-6 py-3">
                <div
                  className="bg-zinc-100 focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 w-72 justify-between flex self-center items-center border border-zinc-200 hover:cursor-not-allowed"
                  type="button"
                >
                  <span className="text-black">{descricaoOr}</span>
                </div>
              </td>
            </>
          ) : (
            <>
              <td className="px-6 py-3"></td>
              <td className="px-6 py-3">
                <div className="flex gap-2">
                  <span>{value}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="self-center w-5 h-5 text-red-800 dark:text-red-400"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2.25a.75.75 0 01.75.75v16.19l6.22-6.22a.75.75 0 111.06 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 111.06-1.06l6.22 6.22V3a.75.75 0 01.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </td>
              <td className="px-6 py-3">
                <div className="flex items-center justify-center w-full ">
                  <div
                    className="bg-zinc-100 focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 w-72 justify-between flex self-center items-center border border-zinc-200 hover:cursor-not-allowed"
                    type="button"
                  >
                    <span className="text-black">{descricaoOr}</span>
                  </div>
                </div>
              </td>
            </>
          )}
        </tr>
      ) : (
        <tr className="h-16 border-b even:bg-gray-100 dark:even:bg-slate-700">
          <td className="px-6 py-3 font-semibold">{`${dia}/${mes}/${ano}`}</td>
          <td className="px-6 py-3">{desc}</td>
          {direction ? (
            <>
              <td className="px-6 py-3">
                <div className="flex gap-2">
                  <span>{value}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="self-center w-5 h-5 text-green-800 dark:text-green-400"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.47 2.47a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06l-6.22-6.22V21a.75.75 0 01-1.5 0V4.81l-6.22 6.22a.75.75 0 11-1.06-1.06l7.5-7.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </td>
              <td className="px-6 py-3"></td>
              <td className="px-6 py-3">
                <div className="flex items-center justify-center w-full ">
                  <SelectDropdown
                    valueType={valueType}
                    name={auth.user.name}
                    idEnvio={idEnvio}
                    user_id={auth.user.id}
                    row_id={row_id}
                    handleUpdate={handleUpdate}
                    value={value}
                    classification={classification}
                    descricaoOr={descricaoOr}
                    memo={desc}
                    envioStatus={envioStatus}
                    setStatusEnvio={setStatusEnvio}
                  />
                </div>
              </td>
            </>
          ) : (
            <>
              <td className="px-6 py-3"></td>
              <td className="px-6 py-3">
                <div className="flex gap-2">
                  <span>{value}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="self-center w-5 h-5 text-red-800 dark:text-red-400"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2.25a.75.75 0 01.75.75v16.19l6.22-6.22a.75.75 0 111.06 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 111.06-1.06l6.22 6.22V3a.75.75 0 01.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </td>
              <td className="px-6 py-3">
                <div className="flex items-center justify-center w-full ">
                  <SelectDropdown
                    valueType={valueType}
                    name={auth.user.name}
                    idEnvio={idEnvio}
                    user_id={auth.user.id}
                    row_id={row_id}
                    handleUpdate={handleUpdate}
                    value={value}
                    classification={classification}
                    descricaoOr={descricaoOr}
                    memo={desc}
                    envioStatus={envioStatus}
                    setStatusEnvio={setStatusEnvio}
                  />
                </div>
              </td>
            </>
          )}
        </tr>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps)(ExtratoRow);
